export function validatePhone(rule, value,callback) {
    const reg =/^[1][3-9][0-9]{9}$/;
    if(value==''||value==undefined||value==null){
      callback();
    }else {
      if ((!reg.test(value)) && value != '') {
        callback(new Error('请输入正确的电话号码'));
      } else {
        callback();
      }
    }
  }
  export const validateContacts = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入中文'))
    }
    if (!/^[\u0391-\uFFE5A-Za-z]+$/.test(value)) {
      callback(new Error('不可输入特殊字符'))
    } else {
      callback()
    }
  }