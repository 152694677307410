import { render, staticRenderFns } from "./shenbao.vue?vue&type=template&id=57e620da&scoped=true&"
import script from "./shenbao.vue?vue&type=script&lang=js&"
export * from "./shenbao.vue?vue&type=script&lang=js&"
import style0 from "./shenbao.vue?vue&type=style&index=0&id=57e620da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57e620da",
  null
  
)

export default component.exports