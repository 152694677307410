<template>
  <div class="box">
    <a name="mao"></a>
    <div class="header">
      <div class="kong"></div>
      <div class="logo">
        <img src="../assets/img/shenqing/校园足球LOGO.png" alt="" />
      </div>
      <div class="headerbei">
        <img src="../assets/img/shenqing/图层 6.png" alt="" />
      </div>
    </div>
    <div v-show="toutu1" class="content">
      <div class="touuu" v-show="san1">
        <div class="frist"><b>只要您的园所满足以下条件:</b></div>
        <p>
          1.经政府部门备案及审批通过的学前办学机构，且有意愿成为2021年全国足球特色幼儿园的园所。
        </p>
        <p>2.具备开展幼儿足球活动硬件设施及师资要求。</p>
        <p>
          3.园所以《全国足球特色幼儿园游戏活动指南》为纲，开展幼儿足球的教学工作。
        </p>
        <p>
          4.自愿在系统平台注册成功并完善相关信息的幼儿园，完成实名认证、班级、教师及全园幼儿信息录入。
        </p>
        <p>5.园所在幼儿足球游戏教学活动中，不得出现八项负面清单内容:</p>
        <div class="fumian">
          <div class="kk">
            <div>禁止进行正式足球比赛;</div>
            <div>禁止幼儿足球考级;</div>
            <div>禁止足球操等形式化的表演;</div>
            <div>禁止全国足特幼儿园只练足球;</div>
          </div>
          <div class="kk">
            <div>禁止进行成人化、专业化、小学化的足球训练;</div>
            <div>禁止进行足球的头球练习;</div>
            <div>禁止所有负重的力量练习;</div>
            <div>严格控制运动时长和运动的强度;</div>
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="tixing" v-show="san1">
        <div class="frist"><b>特别提醒</b></div>
        <p>
          1.此评审标准要求申报园所，必须如实填报相关信息，通过评选标准认定全国足特园资格，如后期发现有不实填报、违规园所，则全国校足办按规定取消其全国足球特色幼儿园资格。
        </p>
        <p>2.足特园申报需要使用园长账号登录平台讲行申报。</p>
        <div class="qeuding">
          <div class="zhinan">
            <img
              class="kaobei"
              src="../assets/img/shenqing/文件 拷贝.png"
              alt=""
            />
            <p class="caozuo">
              <a target="_blank" href="/2021全国足球特色幼儿园申报操作指南.pdf"
                >《操作指南》</a
              >
            </p>
          </div>
          <div v-show="diyi" class="qeuding danxuan">
            <div class="kuang" @click="xianshi()">
              <img
                v-show="flag"
                style="width: 0.385rem; /* 38.5/100 */"
                src="../assets/img/shenqing/形状 1.png"
                alt=""
              />
            </div>
            <div class="anniu">我承诺将按照申报要求如实填报</div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="diyi" class="lijishen">
      <button @click="tianbao()" class="lijishen">
        <span v-if="this.tongji.isFinish == 2">查看申报信息</span>
        <span v-else-if="this.tongji.isFinish == 1">继续申报</span>
        <span v-else>立即申请</span>
      </button>
    </div>
    <div v-show="toutu2" class="tutu">
      <!-- 统计 -->
      <div>
        <div class="wid">
          <div>
            <div style="text-align: center">
              <div class="mingcheng">积分统计</div>
            </div>
            <div class="statistics1">
              <el-form
                :model="ruleForm"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item>
                  <td>总积分</td>
                  <el-input
                    :readonly="true"
                    :disabled="true"
                    v-model="tongji.totalIntegral"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <td>基础积分</td>
                  <el-input
                    :readonly="true"
                    :disabled="true"
                    v-model="tongji.initIntegral"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <td>教师积分</td>
                  <el-input
                    :readonly="true"
                    :disabled="true"
                    v-model="tongji.teacherIntegral"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <td>幼儿积分</td>
                  <el-input
                    :readonly="true"
                    :disabled="true"
                    v-model="tongji.studentIntegral"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <td class="jifen">
                    总积分超过本省
                    <span class="aaaa">
                      {{
                        (
                          (tongji.provinceRanking /
                            tongji.provinceSchoolCount) *
                          100
                        ).toFixed(0) + "%"
                      }}
                    </span>
                    园所
                  </td>
                </el-form-item>
                <el-form-item>
                  <td class="quanguo">
                    总积分超过全国
                    <span class="aaaa">
                      {{
                        (
                          (tongji.nationalRanking /
                            tongji.nationalSchoolCount) *
                          100
                        ).toFixed(0) + "%"
                      }}
                    </span>
                    园所
                  </td>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="wid">
          <div>
            <div style="text-align: center">
              <div class="mingcheng">上课统计</div>
            </div>
            <div class="statistics1">
              <el-form
                :model="ruleForm"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item>
                  <td>上课人数</td>
                  <el-input
                    :readonly="true"
                    :disabled="true"
                    v-model="tongji.classStuNumber"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <td>上课人次</td>
                  <el-input
                    :readonly="true"
                    :disabled="true"
                    v-model="tongji.courseViews"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="wid">
          <div>
            <div style="text-align: center">
              <div class="mingcheng">测试统计</div>
            </div>
            <div class="statistics1">
              <el-form
                :model="ruleForm"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item>
                  <td>体质测试人数</td>
                  <el-input
                    :readonly="true"
                    :disabled="true"
                    v-model="tongji.bodytestingStunum"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <td>运动测试人数</td>
                  <el-input
                    :readonly="true"
                    :disabled="true"
                    v-model="tongji.sporttestingStunum"
                  ></el-input>
                </el-form-item>
                <el-form-item id="zongceshi">
                  <td>总测试人数</td>
                  <el-input
                    :readonly="true"
                    :disabled="true"
                    v-model="tongji.totalTestingStunum"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <td>体质测试人次</td>
                  <el-input
                    :readonly="true"
                    :disabled="true"
                    v-model="tongji.bodytestingNum"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <td>运动测试人次</td>
                  <el-input
                    :readonly="true"
                    :disabled="true"
                    v-model="tongji.sporttestingNum"
                  ></el-input>
                </el-form-item>
                <el-form-item id="renci">
                  <td>总测试人次</td>
                  <el-input
                    :readonly="true"
                    :disabled="true"
                    v-model="tongji.totalTestingNum"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="wid">
          <div>
            <div style="text-align: center">
              <div class="mingcheng">评估统计</div>
            </div>
            <div class="statistics1">
              <el-form
                :model="ruleForm"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form
                  :model="ruleForm"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <el-form-item>
                    <td>幼儿报告份数</td>
                    <el-input
                      :readonly="true"
                      :disabled="true"
                      v-model="tongji.stuReportNum"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <td>班级报告份数</td>
                    <el-input
                      :readonly="true"
                      :disabled="true"
                      v-model="tongji.gradeReportNum"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <td>园所报告份数</td>
                    <el-input
                      :readonly="true"
                      :disabled="true"
                      v-model="tongji.schoolReportNum"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <td>报告总份数</td>
                    <el-input
                      :readonly="true"
                      :disabled="true"
                      v-model="tongji.totalReportNum"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </el-form>
            </div>
          </div>
        </div>
        <div
          style="width: 8.12rem; margin: 20px auto 40px; display: flex; flex: 1"
          class="shuju"
        >
          <div class="tishi">
            <img src="../assets/img/shenqing/提示图标.png" alt="" />
          </div>
          <div style="padding-top: 20px">
            以上数据由园所使用系统平台进行教学活动产生，是全国足球特色幼儿园评选的重要量化指标，您积极利用系统平台开展教学工作，以期获取更高积分。
          </div>
        </div>
        <div v-show="xiayi1" style="text-align: center">
          <button @click="xiayibu" class="xiayibu">下一步</button>
        </div>
      </div>
    </div>

    <div class="tu1 next1">
      <div class="beij"></div>
      <div class="xinxi">
        <img src="../assets/img/shenqing/园所基础信息.jpg" alt="" />
      </div>
    </div>
    <div class="tu1">
      <img v-show="diyi1" src="../assets/img/shenqing/组 26.png" alt="" />
      <div v-show="dier" class="box2">
        <div class="erji">
          <el-form
            label-width="100px"
            class="demo-ruleForm"
            ref="rulesForm"
            :rules="rules"
            :model="rulesForm"
          >
            <el-form-item>
              <td>幼儿园名称</td>
              <el-input
                :readonly="true"
                :disabled="true"
                v-model="schoollist.schoolName"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <td>幼儿园编码</td>
              <el-input
                :readonly="true"
                :disabled="true"
                v-model="schoollist.sn"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <td>幼儿园地址</td>
              <el-input
                :readonly="true"
                :disabled="true"
                v-model="schoollist.city"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <td>注册时间</td>
              <div style="border: 1px solid #dcdfe6; font-size: 0.2rem">
                {{ schoollist.createtime | formatDate }}
              </div>
            </el-form-item>
            <el-form-item>
              <td>幼儿园性质</td>
              <el-input
                :readonly="true"
                :disabled="true"
                v-model="xingzhi"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <td>平台幼儿数</td>
              <el-input
                :readonly="true"
                :disabled="true"
                v-model="$store.state.schoolInfo.stuCount"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <td>平台教师数</td>
              <el-input
                :readonly="true"
                :disabled="true"
                v-model="$store.state.schoolInfo.teaCount"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <td>平台班级数</td>
              <el-input
                :readonly="true"
                :disabled="true"
                v-model="$store.state.schoolInfo.gradeCount"
              ></el-input>
            </el-form-item>
            <el-form-item prop="contacts">
              <td>联系人姓名</td>
              <el-input
                :readonly="jinyong"
                :disabled="jinyong"
                v-model="rulesForm.contacts"
              ></el-input>
            </el-form-item>
            <el-form-item prop="contactsMobile">
              <td>联系人电话</td>
              <el-input
                :readonly="jinyong"
                :disabled="jinyong"
                v-model="rulesForm.contactsMobile"
              ></el-input>
            </el-form-item>
            <el-form-item prop="contactsEmail" id="youxiang">
              <td>联系人邮箱</td>
              <el-input
                :readonly="jinyong"
                :disabled="jinyong"
                v-model="rulesForm.contactsEmail"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="renzheng">
          <p v-if="shiming == '88'" style="width: 3.57rem; text-align: center">
            园所已实名认证
          </p>
          <p
            v-else-if="shiming == '1'"
            style="width: 3.57rem; text-align: center"
          >
            园所实名认证审核中
          </p>
          <p
            v-else-if="shiming == '2'"
            style="width: 2.07rem; text-align: center"
          >
            园所实名审核失败
          </p>
          <p v-else style="width: 2.07rem; text-align: center">
            园所未实名认证
          </p>
          <button
            v-if="shiming == '0' || shiming == '2'"
            @click="renzhneg()"
            class="qurenzheng"
          >
            去认证
          </button>
        </div>
        <div style="margin-left: 80px">
          <div class="box3">
            <b>
              您的园所信息完善程度将会影响足特园申报，请在8月31日前完善相关信息。
            </b>
          </div>
        </div>
        <div class="xiayi">
          <!-- <button @click="baocun">保存</button> -->
          <button @click="nextxia('rulesForm')">
            <a href="#mao" class="kuai">{{
              jinyong ? "下一步" : "保存并下一步"
            }}</a>
          </button>
        </div>
      </div>
      <div class="teshu">
        <div v-show="toutu3">
          <div class="over">
            <div class="over-1">
              <div>
                <div class="linian">教学理念</div>
              </div>
              <el-form
                ref="rulesForm1"
                :rules="rules1"
                class="demo-ruleForm"
                :model="rulesForm1"
              >
                <el-form-item prop="teachingConcept" id="zongceshi">
                  <td>*1.阐述幼儿足球活动理念</td>
                  <el-input
                    type="textarea"
                    autosize
                    ref="teachingConcept"
                    @blur="sure('teachingConcept', rulesForm1.teachingConcept)"
                    :readonly="jinyong"
                    :disabled="jinyong"
                    v-model="rulesForm1.teachingConcept"
                  ></el-input>
                </el-form-item>
                <el-form-item id="zongceshi" prop="isRules">
                  <td>*2.园所在幼儿足球教学中是否遵循八项负面清单的要求</td>
                  <div>
                    <el-radio-group v-model="rulesForm1.isRules">
                      <el-radio :disabled="jinyong" :label="1">是</el-radio>
                      <el-radio :disabled="jinyong" :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="over">
            <div class="over-1">
              <div>
                <div class="linian">设施及师资</div>
              </div>
              <el-form
                ref="rulesForm2"
                :rules="rules1"
                class="demo-ruleForm"
                :model="rulesForm1"
              >
                <el-form-item id="zongceshi" prop="siteConditions">
                  <td>
                    *1.阐述体育场地的情况（运动场面积、场地类型、规格及数量）
                  </td>
                  <el-input
                    type="textarea"
                    autosize
                    @blur="sure('siteConditions', rulesForm1.siteConditions)"
                    :readonly="jinyong"
                    :disabled="jinyong"
                    v-model="rulesForm1.siteConditions"
                  ></el-input>
                </el-form-item>
                <el-form-item id="zongceshi" prop="roomNum">
                  <td>*2.填写配备电子显示屏的活动室数量</td>
                  <el-input-number
                    @blur="sure('roomNum', rulesForm1.roomNum)"
                    :disabled="jinyong"
                    v-model="rulesForm1.roomNum"
                    controls-position="right"
                    :min="0"
                    :max="99"
                  ></el-input-number>
                </el-form-item>
                <el-form-item id="zongceshi" prop="footballEquipment">
                  <td>*3.阐述幼儿足球活动器材、教具等配备情况</td>
                  <el-input
                    type="textarea"
                    autosize
                    @blur="
                      sure('footballEquipment', rulesForm1.footballEquipment)
                    "
                    :readonly="jinyong"
                    :disabled="jinyong"
                    v-model="rulesForm1.footballEquipment"
                  ></el-input>
                </el-form-item>
                <el-form-item id="zongceshi" prop="teachingSituation">
                  <td>
                    *4.阐述师资配备情况（填写教师总数量及具备幼儿足球教师数量，及参与进修、培训等情况说明）
                  </td>
                  <el-input
                    type="textarea"
                    autosize
                    @blur="
                      sure('teachingSituation', rulesForm1.teachingSituation)
                    "
                    :readonly="jinyong"
                    :disabled="jinyong"
                    v-model="rulesForm1.teachingSituation"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="oover">
            <div class="over-1">
              <div>
                <div class="linian">教学开展记录</div>
              </div>

              <el-form
                :rules="rules1"
                class="demo-ruleForm"
                ref="rulesForm3"
                :model="rulesForm1"
              >
                <el-form-item id="zongceshi" prop="teachingRecords">
                  <td>*1.阐述幼儿足球教学方式，并对于教学活动进行记录</td>
                  <el-input
                    type="textarea"
                    autosize
                    @blur="sure('teachingRecords', rulesForm1.teachingRecords)"
                    :readonly="jinyong"
                    :disabled="jinyong"
                    v-model="rulesForm1.teachingRecords"
                  ></el-input>
                </el-form-item>
                <el-form-item id="zongceshi">
                  <td>2.上传幼儿教学活动记录（照片以及视频）</td>
                  <div class="tupian">
                    <el-upload
                      :action="
                        constant.UPLOAD_URL +
                        '/footballFile/upload/' +
                        this.$store.state.userInfo.schoolId
                      "
                      :readonly="jinyong"
                      :disabled="jinyong"
                      :headers="{ token: $store.state.userInfo.token }"
                      list-type="picture-card"
                      :auto-upload="true"
                      :on-change="handleChange"
                      :limit="3"
                      :show-file-list="true"
                      :file-list="fileList"
                      :on-remove="handleRemove"
                      :on-success="handlesuccess"
                      :on-exceed="handleImageExceed"
                      :model="rulesForm1.teachingImages"
                      :before-upload="beforeAvatarUpload"
                      :on-preview="handlePictureCardPreview"
                    >
                      <img src="../assets/img/shenqing/图片.png" alt="" />
                    </el-upload>
                    <!-- 上传视频-->
                    <el-upload
                      ref="upload"
                      :readonly="jinyong"
                      :disabled="jinyong"
                      :limit="3"
                      :headers="{ token: $store.state.userInfo.token }"
                      class="upload-demo"
                      :action="
                        constant.UPLOAD_URL +
                        '/vod/video/footballUploadVideo/' +
                        this.$store.state.userInfo.schoolId
                      "
                      :file-list="fileVideo"
                      list-type="picture-card"
                      :before-upload="beforeAvatarUploadvideo"
                      :on-remove="handleRemovevideo"
                      :on-success="handleVideosuccess"
                      :on-exceed="handleVideoExceed"
                      :show-file-list="true"
                    >
                      <img src="../assets/img/shenqing/视频.png" alt="" />
                    </el-upload>
                  </div>
                </el-form-item>
                <el-form-item id="zongceshi" prop="schoolHonor">
                  <td>*3.列举园所获取的幼儿足球荣誉</td>
                  <el-input
                    type="textarea"
                    autosize
                    @blur="sure('schoolHonor', rulesForm1.schoolHonor)"
                    :readonly="jinyong"
                    :disabled="jinyong"
                    v-model="rulesForm1.schoolHonor"
                  ></el-input>
                </el-form-item>
                <el-form-item id="zongceshi" prop="footballMoney">
                  <td>*4.列举园所在幼儿足球教学上的经费投入</td>
                  <el-input
                    type="textarea"
                    autosize
                    @blur="sure('footballMoney', rulesForm1.footballMoney)"
                    :readonly="jinyong"
                    :disabled="jinyong"
                    v-model="rulesForm1.footballMoney"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="over-1">
              <div>
                <div class="linian">教学规划</div>
              </div>
              <el-form
                :rules="rules1"
                class="demo-ruleForm"
                ref="rulesForm4"
                :model="rulesForm1"
              >
                <el-form-item id="zongceshi" prop="schoolPlan">
                  <td>*1.阐述本学年度园所幼儿足球教学规划</td>
                  <el-input
                    type="textarea"
                    autosize
                    @blur="sure('schoolPlan', rulesForm1.schoolPlan)"
                    :readonly="jinyong"
                    :disabled="jinyong"
                    v-model="rulesForm1.schoolPlan"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div v-if="!jinyong" class="yang">
                您可以在提交的过程中，随时点击“保存”，将您已经填入的信息进行保存，以便于您暂停后继续申报。如果您的信息已经全部填入完成，请点击“提交”，完成申报。
              </div>
              <div v-if="!jinyong" class="tijian">
                <button class="anjian" @click="tijiaobao">保存</button>
                <button
                  class="anjian"
                  @click="
                    tijiao(
                      'rulesForm1',
                      'rulesForm2',
                      'rulesForm3',
                      'rulesForm4'
                    )
                  "
                >
                  提交
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <div class="foot"></div>
    <div class="liang">
      <img src="../assets/img/shenqing/两张.png" alt="" />
    </div>
    <comdialog
      ref="comdialog"
      :params.sync="alertInfo"
      @onSubmit="onSubmit"
      @close="comdialogClose"
    >
      <template slot="desc">
        <div class="template_desc">
          上传的证件必须和填写的社会统一信用代码或办学许可证号一致
        </div>
      </template>
    </comdialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog :visible.sync="dialogVideoVisible">
      <video
        v-if="dialogVideoUrl != ''"
        style="width: 100%; height: 300px"
        :src="dialogVideoUrl"
        class="avatar"
        controls="controls"
      >
        您的浏览器不支持视频播放
      </video>
    </el-dialog>
  </div>
</template>
<script>
import { validatePhone } from "./shenqing.js";
import comdialog from "@/components/admin/editSchoolInfoDialog.vue";
export default {
  data() {
    return {
      flag: false,
      flag1: false,
      wanshan: "您的足特园申报表还没填完,请您尽快完成资料",
      beijing: false,
      diyi: true,
      diyi1: true,
      bei: true,
      youerlu: 0,
      toutu3: false,
      dier: false,
      san1: true,
      toutu1: true,
      jinyong: true,
      right1: true,
      toutu2: false,
      xiayi1: true,
      tongji: [],
      achool: {
        teachingConcept: "",
        isRules: "",
        siteConditions: "",
        roomNum: "",
        footballEquipment: "",
        teachingSituation: "",
        teachingRecords: "",
        schoolHonor: "",
        footballMoney: "",
        schoolPlan: "",
        schoolId: this.$store.state.userInfo.schoolId,
      },
      dialogImageUrl: "",
      shiming: 0,
      num: 0,
      xingzhi: "",
      radio: "0",
      schoollist: [],
      ruleForm: {
        name: "",
      },
      //图片回显
      fileList: [],
      //视频回显
      fileVideo: [],
      rulesForm: {
        contacts: "",
        contactsMobile: "",
        contactsEmail: "",
        schoolId: this.$store.state.userInfo.schoolId,
      },
      rules: {
        contacts: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
          { trigger: "blur" },
        ],
        contactsMobile: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
          { validator: validatePhone, trigger: "blur" },
        ],
        contactsEmail: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
      rulesForm1: {
        teachingConcept: "",
        isRules: "",
        siteConditions: "",
        roomNum: "",
        footballEquipment: "",
        teachingSituation: "",
        teachingRecords: "",
        schoolHonor: "",
        footballMoney: "",
        schoolPlan: "",
        teachingImages: [],
      },
      rules1: {
        teachingConcept: [
          {
            required: true,
            message: "请阐述幼儿足球活动理念",
            trigger: "blur",
          },
        ],
        isRules: [
          {
            required: true,
            message: "请填写配备电子显示屏的活动室数量",
            trigger: "blur",
          },
        ],
        siteConditions: [
          {
            required: true,
            message: "请阐述体育场地的情况（运动场面积、场地类型、规格及数量）",
            trigger: "blur",
          },
        ],
        roomNum: [
          {
            required: true,
            message: "请填写配备电子显示屏的活动室数量",
            trigger: "blur",
          },
        ],
        footballEquipment: [
          {
            required: true,
            message: "请阐述幼儿足球活动器材、教具等配备情况",
            trigger: "blur",
          },
        ],
        teachingSituation: [
          {
            required: true,
            message:
              "请阐述师资配备情况（填写教师总数量及具备幼儿足球教师数量，及参与进修、培训等情况说明）",
            trigger: "blur",
          },
        ],
        teachingRecords: [
          {
            required: true,
            message: "请阐述幼儿足球教学方式，并对于教学活动进行记录",
            trigger: "blur",
          },
        ],
        schoolHonor: [
          {
            required: true,
            message: "请阐述幼儿足球活动器材、教具等配备情况",
            trigger: "blur",
          },
        ],
        footballMoney: [
          {
            required: true,
            message:
              "请阐述师资配备情况（填写教师总数量及具备幼儿足球教师数量，及参与进修、培训等情况说明）",
            trigger: "blur",
          },
        ],
        schoolPlan: [
          {
            required: true,
            message:
              "请阐述师资配备情况（填写教师总数量及具备幼儿足球教师数量，及参与进修、培训等情况说明）",
            trigger: "blur",
          },
        ],
      },
      numStr: 0,
      Y1: 0,
      ratio: 1,
      alertInfo: {},
      dialogVisible: false,
      dialogVideoVisible: false,
      dialogVideoUrl: "",
    };
  },

  filters: {
    //毫秒时间转化为yyyy-MM-dd HH:mm:ss
    formatDate: function (seconds) {
      let a = seconds * 1000;
      var datetime = new Date(a);
      var year = datetime.getFullYear(),
        month =
          datetime.getMonth() + 1 < 10
            ? "0" + (datetime.getMonth() + 1)
            : datetime.getMonth() + 1,
        day =
          datetime.getDate() < 10
            ? "0" + datetime.getDate()
            : datetime.getDate(),
        hour =
          datetime.getHours() < 10
            ? "0" + datetime.getHours()
            : datetime.getHours(),
        min =
          datetime.getMinutes() < 10
            ? "0" + datetime.getMinutes()
            : datetime.getMinutes(),
        sec =
          datetime.getSeconds() < 10
            ? "0" + datetime.getSeconds()
            : datetime.getSeconds();
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec
      );
    },
  },
  created() {
    this.show();
    this.youer();
  },
  components: {
    comdialog,
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleVideoPreview(file) {
      console.log(file);
      this.dialogVideoUrl = file.url;
      this.dialogVideoVisible = true;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isJPG2 = file.type === "image/jpg";
      const isPNG = file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 30;
      if (!isJPG && !isJPG2 && !isPNG) {
        this.$message.warning("只支持jpg或png格式图片");
      }
      if (!isLt5M) {
        this.$message.warning("请上传30MB以内的图片!");
      }
      return (isJPG || isJPG2 || isPNG) && isLt5M;
    },
    //上传视频成功
    handlesuccess(response, file, fileList) {
      console.log("response", response);
      console.log("file", file.url);
      console.log("fileList", fileList);
    },
    handleImageExceed() {
      this.$message.warning("最多上传3张图片");
    },
    handleVideoExceed() {
      this.$message.warning("最多上传3个视频");
    },
    handleVideosuccess(response, file, fileList) {
      file.url =
        "https://footballcn.oss-cn-beijing.aliyuncs.com/uploads/20210706/9f2c4def2f16655f1c9ea1d7eb2fac89.png";
      console.log("response", response);
      console.log("file", file);
      console.log("fileList", fileList);
    },
    //删除图片
    handleRemove(file) {
      console.log("删除地址", file);
      let url = "";
      if (file.response) {
        url = file.response.data.url;
      } else {
        let arr = file.url.split("com");
        url = arr[1];
      }
      let data = {
        ways: "0",
        url: url,
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.admin.deleteFile(data).then((res) => {
        console.log("提交", res);
      });
    },
    //删除视频
    handleRemovevideo(file) {
      console.log(file);
      let url = "";
      if (file.response) {
        url = file.response.data;
      } else {
        url = file.videoId;
      }
      if (url) {
        let data = {
          ways: "1",
          url: url,
          schoolId: this.$store.state.userInfo.schoolId,
        };
        this.api.admin.deleteFile(data).then((res) => {
          console.log("提交", res);
        });
      }
    },
    beforeAvatarUploadvideo(file) {
      console.log("aaa");
      const isLt10M = file.size / 1024 / 1024 < 30;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过30MB哦!");
        return false;
      }
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    xiayibu() {
      this.toutu3 = true;
      this.xiayi1 = false;
      this.diyi1 = false;
    },
    onSubmit(value) {
      //完善信息
      // 数据处理  图片链接切割
      let val = JSON.parse(value);
      console.log(value);
      console.log(val);
      val.intefile = val.intefile.map((item) => {
        // console.log(item);
        if (typeof item === "string") {
          return item.split(".com")[1];
        }
        return item.url.split(".com")[1];
      });
      val.intefile = val.intefile.join(",");
      val.schoolLogo = val.schoolLogo.split(".com")[1];
      val.equment = val.equment || " ";
      val.square = val.square || " ";
      val.slogan = val.slogan || " ";
      // 提交接口
      this.api.admin
        .atteSchoolEdit(this.$store.state.userInfo.schoolId, val)
        .then((res) => {
          if (res.flag) {
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          } else {
            this.$notice({
              message: res.message,
            }).isShow();
          }
          this.$refs.comdialog.isClose();
          console.log("aaaa", res.data);
          this.shiming = res.data;
        });
    },
    comdialogClose() {},
    show() {
      this.api.admin
        .atteSchool(this.$store.state.userInfo.schoolId)
        .then((res) => {
          this.schoollist = res.data;
          if (res.data.type == "1") {
            this.xingzhi = "私立";
          } else {
            this.xingzhi = "公立";
          }
          // 处理完善信息显示数据
          this.alertInfo = { ...res.data };
          this.alertInfo.schoolLogo = this.alertInfo.schoolLogo
            ? this.constant.URL_admin + this.alertInfo.schoolLogo
            : "";
          this.alertInfo.type = parseInt(this.alertInfo.type);

          if (this.alertInfo.intefile) {
            this.alertInfo.intefile =
              this.alertInfo.intefile && this.alertInfo.intefile.split(",");
            this.alertInfo.intefile =
              this.alertInfo.intefile &&
              this.alertInfo.intefile.map((item) => {
                return { url: this.constant.URL_admin + item };
              });
          } else {
            this.alertInfo.intefile = [];
          }
        });
      this.api.admin
        .footballApply(this.$store.state.userInfo.schoolId)
        .then((res) => {
          console.log("资料查询", res.data);
          this.tongji = res.data;
          if (res.data.isFinish == 1 || res.data.isFinish == 2) {
            this.flag = true;
          }
          this.rulesForm.contacts = res.data.contacts;
          this.rulesForm.contactsMobile = res.data.contactsMobile;
          this.rulesForm.contactsEmail = res.data.contactsEmail;
          this.fileList = res.data.imageList;
          this.fileVideo = res.data.videoList;
          this.shiming = res.data.auditState;
          this.rulesForm1.teachingConcept = res.data.teachingConcept;
          this.rulesForm1.isRules = res.data.isRules;
          this.rulesForm1.siteConditions = res.data.siteConditions;
          this.rulesForm1.roomNum = res.data.roomNum;
          this.rulesForm1.footballEquipment = res.data.footballEquipment;
          this.rulesForm1.teachingSituation = res.data.teachingSituation;
          this.rulesForm1.teachingRecords = res.data.teachingRecords;
          this.rulesForm1.schoolHonor = res.data.schoolHonor;
          this.rulesForm1.footballMoney = res.data.footballMoney;
          this.rulesForm1.schoolPlan = res.data.schoolPlan;
          this.rulesForm1.teachingImages = res.data.imageList;
          if (res.data.isEditor == 1) {
            this.jinyong = true;
          } else {
            this.jinyong = false;
          }
        });
    },
    sure(name, value) {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      data[name] = value;
      this.api.admin.updateInput(data).then((res) => {
        console.log("提交", res);
      });
    },
    youer() {
      this.api.admin
        .findStuRegistered(this.$store.state.userInfo.schoolId)
        .then((res) => {
          this.youerlu = res.data;
        });
    },
    tijiao(rulesForm1, rulesForm2, rulesForm3, rulesForm4) {
      let canPost = true;
      this.$refs[rulesForm1].validate((valid) => {
        if (valid) {
          console.log("addss");
        } else {
          canPost = false;
          this.$alert("您输入的信息不正确，请检查后重新输入", "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
          canPost = false;
          return false;
        }
      });
      this.$refs[rulesForm2].validate((valid) => {
        if (valid) {
          console.log("addss");
        } else {
          canPost = false;
          this.$alert("您输入的信息不正确，请检查后重新输入", "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
          return false;
        }
      });
      this.$refs[rulesForm3].validate((valid) => {
        if (valid) {
          console.log("addss");
        } else {
          canPost = false;
          this.$alert("您输入的信息不正确，请检查后重新输入", "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
          return false;
        }
      });
      this.$refs[rulesForm4].validate((valid) => {
        if (valid) {
          console.log("addss");
        } else {
          canPost = false;
          this.$alert("您输入的信息不正确，请检查后重新输入", "提示", {
            confirmButtonText: "确定",
            callback: () => {},
          });
          return false;
        }
      });
      if (canPost) {
        let that = this;
        this.achool.teachingConcept = this.rulesForm1.teachingConcept;
        this.achool.isRules = this.rulesForm1.isRules;
        this.achool.siteConditions = this.rulesForm1.siteConditions;
        this.achool.roomNum = this.rulesForm1.roomNum;
        this.achool.footballEquipment = this.rulesForm1.footballEquipment;
        this.achool.teachingSituation = this.rulesForm1.teachingSituation;
        this.achool.teachingRecords = this.rulesForm1.teachingRecords;
        this.achool.schoolHonor = this.rulesForm1.schoolHonor;
        this.achool.footballMoney = this.rulesForm1.footballMoney;
        this.achool.schoolPlan = this.rulesForm1.schoolPlan;
        if (this.youerlu == 0) {
          this.$confirm(
            "您的园所信息完善程度将会影响足特园申报，请在8月31日前完善相关信息。",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              that.api.admin.updateStep2(this.achool).then((res) => {
                console.log("提交", res);
                that.$router.push("/admin/classManagement");
              });
            })
            .catch(() => {});
        } else {
          this.$confirm(
            "您将提交最终申报资料，提交后申报资料不可修改。是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              that.api.admin.updateStep2(this.achool).then((res) => {
                console.log("提交", res);
                that.$router.push("/home");
              });
            })
            .catch(() => {});
        }
      }
    },
    xianshi() {
      if (this.flag == false) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
    tianbao() {
      if (this.flag == true) {
        this.dier = true;
        this.diyi = false;
        this.diyi1 = false;
        this.flag1 = true;
        this.beijing = true;
        this.right1 = false;
      } else {
        this.$message.error("请勾选我承诺将按照申报要求如实填报");
      }
    },
    baocun() {
      this.api.admin.updateStep1(this.rulesForm).then((res) => {
        console.log(res);
        this.$message({
          message: "保存成功",
          type: "success",
        });
      });
    },
    tijiaobao() {
      let data = JSON.parse(JSON.stringify(this.rulesForm1));
      delete data.teachingImages;
      data.schoolId = this.$store.state.userInfo.schoolId;
      this.api.admin.updateStep1(data).then((res) => {
        console.log("提交", res);
        if(res.flag){
          this.$message({
            message: "保存成功",
            type: "success",
          });
        }else{
          this.$message({
            message: res.message,
            type: "error",
          });
        }
        
      });
    },
    nextxia(rulesForm) {
      if (this.shiming == 88 || this.shiming == 1) {
        this.$refs[rulesForm].validate((valid) => {
          if (valid) {
            if (this.toutu1 == true) {
              this.toutu1 = false;
              this.diyi1 = true;
              this.toutu2 = true;
              this.dier = false;
              this.bei = false;
            } else {
              this.toutu1 = true;
            }
            this.api.admin.updateStep1(this.rulesForm).then((res) => {
              console.log("res", res);
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        this.$message.error("未实名认证");
      }
    },
    luru() {
      if (this.$store.state.userInfo.teacherId) {
        this.$router.push({ path: "/admin/teacherHome" });
      } else {
        this.$router.push({ path: "/admin/schoolHome" });
      }
    },
    //打开编辑弹窗
    renzhneg() {
      this.$refs.comdialog.isOpen();
      this.alertStatus = "edit";
    },
  },
};
</script>
<style scoped>
.logo {
  text-align: center;
  margin-top: 0.3rem /* 30/100 */;
  margin-bottom: 0.32rem /* 32/100 */;
}
.logo > img {
  width: 1.37rem /* 137/100 */;
  height: 1.37rem /* 137/100 */;
}
.kong {
  width: 100%;
  height: 0.3rem /* 30/100 */;
  background: #eeeced;
}
.erji > .el-form .el-form-item {
  margin-bottom: 6px;
  width: 5.1685rem /* 516.85/100 */;
}
.statistics1 > .el-form .el-form-item {
  margin-bottom: 6px;
  width: 5.1685rem /* 516.85/100 */;
  font-size: 0.23rem;
}
.statistics1 td {
  font-size: 0.2rem; /* 20/100 */
}
.statistics1 .el-input {
  font-size: 0.2rem; /* 20/100 */
}
#zongceshi {
  width: 10.67rem;
  margin: 0 auto 0.1rem /* 10/100 */;
}
.kuai {
  display: block;
  width: 3.74rem /* 374/100 */;
  font-size: 0.22rem /* 22/100 */;
  color: #fff;
  height: 0.55rem /* 55/100 */;
  line-height: 0.55rem /* 55/100 */;
}
.content {
  margin-bottom: 0.3rem /* 30/100 */;
  margin-top: 0.59rem /* 59/100 */;
}
.el-input {
  width: 100%;
}
.next1 {
  margin-top: 0.3rem /* 30/100 */;
}
#youxiang {
  width: 10.75rem;
  margin: 0 auto;
}
#youxiang > .el-form > .el-form-item[data-v-58d5f768] {
  width: 10rem;
  margin: 0 auto;
}

.box2 {
  width: 11rem /* 1160/100 */;
  margin: 0.44rem /* 44/100 */ auto;
}
.erji {
  display: flex;
  justify-content: space-around;
}
.erji td {
  font-size: 0.2rem; /* 20/100 */
}
.erji .el-input {
  font-size: 0.18rem; /* 20/100 */
}
.demo-ruleForm {
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
}
.renzheng {
  border: 1px solid black;
  width: 3.57rem /* 357/100 */;
  height: 0.41rem /* 41/100 */;
  line-height: 0.41rem /* 41/100 */;
  margin: 0.1rem /* 10/100 */ auto 0.1rem /* 10/100 */;
  color: #181717;
  display: flex;
  font-size: 0.2rem /* 20/100 */;
}
.box3 {
  width: 10rem;
  background: #f5f5f5;
  font-size: 0.26rem /* 26/100 */;
  padding: 10px;
  color: #000000;
  margin: 0 auto;
}
.renzheng > button {
  background: #2b0200;
  color: #fff;
  width: 1.5rem /* 150/100 */;
}
#renci {
  width: 10.67rem;
  margin: 0 auto;
}
.txt {
  width: 10.98rem /* 1098/100 */;
  color: #300000;
}
.frist {
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 0.32rem /* 60/100 */;
}
.xiayi {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}
.touuu {
  width: 11rem /* 1100/100 */;
  height: 5.05rem /* 505/100 */;
  font-size: 0.24rem /* 24/100 */;
  font-family: Source Han Sans SC;
  color: #2b0200;
  margin: 0 auto;
}
.touuu p,
.kk > div {
  padding: 0.08rem /* 8/100 */ 0 0.08rem /* 8/100 */ 0;
}
.xiayi > button {
  width: 3.74rem /* 374/100 */;
  background: #2b0200;
  font-size: 0.22rem /* 22/100 */;
  color: #fff;
  height: 0.55rem /* 55/100 */;
}
.kuang {
  width: 0.386rem /* 38.6/100 */;
  height: 0.3855rem /* 38.55/100 */;
  background: #fff;
  border: 1px solid black;
}
.anniu {
  width: 3.8rem /* 380/100 */;
  height: 0.25rem /* 25/100 */;
  font-size: 0.26rem /* 26/100 */;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #393939;
}
.txt p {
  margin: 8px 0;
  font-size: 0.24rem /* 24/100 */;
}
.tutu {
  margin-top: 0.2rem /* 20/100 */;
}
.zhinan {
  width: 1.5rem /* 138/100 */;
}
.qeuding {
  display: flex;
  flex: 1;
}
.danxuan {
  position: relative;
  top: 0.5rem /* 10/100 */;
  left: 2rem /* 200/100 */;
}
.kk {
  float: left;
  margin-right: 40px;
  margin-left: 15px;
}
.kaobei {
  width: 0.81rem /* 81/100 */;
  height: 0.81rem /* 81/100 */;
  border: 1px solid #c8c8c8;
  border-radius: 0.03rem /* 3/100 */;
  margin-top: 0.2rem /* 20/100 */;
  margin-left: 0.4rem /* 40/100 */;
}
.caozuo {
  width: 1.44rem /* 144/100 */;
  height: 0.21rem /* 21/100 */;
  margin: 0 auto;
}
.caozuo > a {
  color: #2b0200;
}
.jifen {
  width: 5.1685rem /* 516.85/100 */;
}
.aaaa {
  padding: 0 10px;
}
.quanguo {
  width: 5.1685rem /* 516.85/100 */;
}
.kk div:after {
  content: " ";
  position: absolute;
  left: -8px;
  top: 8px;
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 4px;
}
.lijishen {
  width: 3.355rem /* 335.5/100 */;
  height: 0.55rem /* 55/100 */;
  background: #2b0200;
  color: #fff;
  margin: 0 auto;
  font-size: 0.3rem /* 30/100 */;
}
.tixing {
  width: 11.6rem /* 1160/100 */;
  height: 3.72rem /* 372/100 */;
  background: #f5f5f5;
  margin: 0 auto;
  font-size: 0.24rem /* 24/100 */;
  padding-top: 0.29rem /* 29/100 */;
  padding-left: 0.29rem /* 29/100 */;
  padding-right: 0.33rem /* 33/100 */;
  color: #2b0200;
}
.tixing > p {
  line-height: 0.4rem /* 40/100 */;
}
.tou > div {
  text-align: center;
}
.erji
  > .demo-ruleForm[data-v-58d5f768]
  > .el-form-item
  > .el-form-item__content {
  font-size: 0.18rem /* 18/100 */;
}
.qurenzheng {
  padding: 0;
  float: right;
  height: 0.41rem /* 41/100 */;
  border: none;
}
.tu1 > img {
  width: 100%;
}
.di {
  display: flex;
  justify-content: center;
}
.liang {
  margin: 0.98rem /* 98/100 */ auto;
  text-align: center;
}
.liang > img {
  width: 8.33rem /* 833/100 */;
  height: 4.03rem /* 403/100 */;
}
.right1 {
  position: absolute;
  right: 3px;
}
.wid {
  margin: 0px auto 30px;
  border-bottom: 1px solid black;
  padding-bottom: 15px;
  width: 11rem /* 1083/100 */;
}
.wid .el-input {
  width: 100%;
}
.tishi > img {
  width: 0.58rem /* 58/100 */;
  height: 0.56rem /* 56/100 */;
}
.textnei {
  color: #fff;
  font-size: 0.2rem /* 20/100 */;
  padding: 25px 50px;

  border: 1px solid #fff;
}
.statistics {
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
}
.mingcheng {
  text-align: center;
  margin: 0 auto;
  width: 3.74rem /* 374/100 */;
  height: 0.55rem /* 55/100 */;
  line-height: 0.55rem;
  background: #2b0200;
  border-radius: 3px;
  font-size: 0.3rem /* 30/100 */;
  color: white;
}

.xiayibu {
  color: #fff;
  width: 3.74rem /* 374/100 */;
  height: 0.55rem /* 55/100 */;
  font-size: 0.3rem /* 30/100 */;
  background: #2b0200;
  border-radius: 3px;
}
.tishitu {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}
.tishitu img {
  width: 88%;
}
.over {
  width: 11rem;
  margin: 35px auto;
  color: #393939;
  border-bottom: 1px solid #393939;
}
.over .el-textarea,
.over-1 .el-textarea,
.oover.el-textarea {
  font-size: 0.18rem /* 18/100 */;
}
.oover {
  width: 11rem;
  margin: 20px auto;
  color: #393939;
}
.tupian {
  display: flex;
  flex: 1;
}
.tupian el-upload--picture-card {
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
}
.tupian el-upload-list--picture-card .el-upload-list__item {
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
}
.tupian el-dialog--center {
  background: #300000;
  border-radius: 10px;
}
.tupian el-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 80%;
  float: right;
}
.tupian el-upload-list__item:first-child {
  margin-top: 0rem;
}
.tupian > div {
  width: 50%;
}
.tupian img {
  width: 100%;
  height: 100%;
}
.over-1 {
  width: 11rem;
  margin: 20px auto;
  font-size: 0.26rem /* 26/100 */;
  padding-bottom: 20px;
}

.linian {
  width: 3.74rem /* 374/100 */;
  height: 0.55rem /* 55/100 */;
  background: #2b0200;
  border-radius: 0.03rem /* 3/100 */;
  line-height: 0.55rem /* 55/100 */;
  text-align: center;
  font-size: 0.3rem /* 30/100 */;
  color: #ffffff;
  margin: 20px auto;
}
.yang {
  margin-top: 10px;
}
.tijian {
  display: flex;

  justify-content: space-around;
  margin-top: 27px;
}
.tijian > button {
  width: 3.74rem /* 374/100 */;
  height: 0.55rem /* 55/100 */;
}
.anjian {
  width: 158px;
  height: 23px;
  color: #fff;
  margin-right: 43px;
  background: #2b0200;
}
.template_desc {
  color: #cb1b31;
  font-size: 20px;
  font-weight: bold;
  padding-left: 200px;
  margin: 30px 0;
}
.ssure {
  width: 30%;
  background: #fff;
  color: #000;
  font-size: 20px;
  margin: 20px 0;
}
.xinxi > img {
  width: 100%;
}
.shuju {
  font-size: 0.2rem /* 20/100 */;
}
.teshu td {
  font-size: 0.2rem /* 20/100 */;
}
.headerbei {
  text-align: center;
  height: 5.7rem /* 560/100 */;
  line-height: 5.7rem /* 560/100 */;
  background-image: url("../assets/img/shenqing/1.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 -2.6rem /* 260/100 */;
}
.headerbei > img {
  width: 5.46rem /* 546/100 */;
}
.foot {
  width: 100%;
  height: 5.6rem /* 560/100 */;
  background-image: url("../assets/img/shenqing/底图背景_bak.png");
  background-repeat: no-repeat;
  background-size: 100% 5.6rem /* 450/100 */;
}
</style>
